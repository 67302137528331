<template>
  <div class="drop-down w-full">
    <button class="drop-down-button" @click.prevent="isMenuOpen = !isMenuOpen">
      <Icon v-if="icon" :name="icon" height="40px" width="40px" stroke="var(--color-black)" />
      <span
        v-if="value && value?.type !== 'default'"
        class="text-nowrap text-left text-sm font-semibold"
      >
        {{ value.label }} {{ value.description }}
      </span>
      <span v-else class="placeholder">
        {{ 'Trier par' }}
      </span>

      <Icon
        v-if="!icon"
        name="arrow-dropdown"
        height="var(--d-icon-size)"
        width="var(--d-icon-size)"
        stroke="var(--color-black)"
      />
    </button>

    <div v-if="isMenuOpen && optionsTypes.length > 0" class="drop-down-menu">
      <div
        v-for="(optionType, optionTypeIndex) in optionsTypes"
        :key="optionType.type"
        class="option-type-wrapper"
      >
        <div class="options">
          <div
            v-for="(option, i) in optionType.options"
            :key="option.label"
            class="drop-down-item"
            :class="{
              'radius-bottom': i === optionsExcluded.length - 1,
              'radius-top': i === 0,
              active: option.value === value?.value
            }"
            @click="
              isMenuOpen = false;
              emit('update:value', option);
            "
          >
            <div v-if="option.type !== 'default'" class="type">
              {{ option.label }}
            </div>
            <div class="text">
              {{ option.description }}
            </div>
          </div>
          <div v-if="!(optionTypeIndex === optionsTypes.length - 1)" class="separator" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DropdownOption, DropdownOptionsTypes } from '@/lib/types/dropdown';

const props = defineProps<{
  value?: DropdownOption;
  options: DropdownOption[];
  icon?: string;
}>();

const emit = defineEmits<(event: 'update:value', value: DropdownOption) => void>();

const isMenuOpen = ref(false);

const optionsExcluded = computed(() =>
  props.options.filter(option => option.value !== props.value?.value && option.type !== 'default')
);

const optionsTypes = ref<DropdownOptionsTypes[]>([]);

watch(
  optionsExcluded,
  () => {
    if (optionsExcluded.value.some(o => o.type !== undefined))
      optionsExcluded.value.forEach(option => {
        const addIndex = optionsTypes.value.findIndex(o => o.type === option.type);
        if (addIndex === -1) {
          optionsTypes.value.push({ type: option.type ?? '', options: [option] });
        } else if (!optionsTypes.value[addIndex]?.options.some(o => o.value === option.value)) {
          optionsTypes.value[addIndex]?.options.push(option);
        }
      });
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use '$/animation.scss';
@use '$/border-radius.scss';
@use '$/colors.scss';
@use '$/mouse-behavior.scss';
@use '$/shadows.scss';
@use '$/button.scss';

.placeholder {
  font-size: 16px;
  font-weight: 600;
  color: colors.$black;

  @media (width <= 768px) {
    margin: 0;
    font-size: 16px;
  }
}

.drop-down {
  font-size: 16px;

  .drop-down-button {
    @include button.outlined(black, $size: var(--d-size), $color-hover: none);
    @include button.with-icon();
  }

  .drop-down-menu {
    @include animation.fade-in(0.5s);

    position: absolute;
    z-index: 5;

    overflow-y: auto;
    flex-direction: column;

    min-width: 200px;
    max-height: 300px;
    margin-top: 55px;
    padding: 16px 12px;

    background-color: white;
    border-radius: border-radius.$default;
    box-shadow: shadows.$page;

    .option-type-wrapper {
      flex-direction: column;

      .options {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .drop-down-item {
    $border-radius: border-radius.$default;

    @include mouse-behavior.clickable-alpha(background-color, colors.$grey-900);

    cursor: pointer;

    flex-direction: row;
    gap: 5px;

    padding: 0 4px;

    background-color: white;
    border-radius: border-radius.$small;

    .type {
      font-weight: 600;
    }

    &.active {
      background-color: colors.$grey-300;
    }
  }

  .separator {
    align-self: center;

    width: 100%;
    height: 1px;
    margin: 6px;

    background-color: colors.$grey-500;
  }
}
</style>
